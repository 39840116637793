<template>
    <div> Admin </div>
</template>

<script>
export default {
    setup() {
        
    },
}
</script>